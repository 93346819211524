import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box, Button, TextField, InputAdornment, Typography,
  Grid, useTheme, CircularProgress, IconButton, Avatar
} from '@mui/material';
import { Tabs, Tab } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ImageIcon from '@mui/icons-material/Image';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import theme from '../theme';
import { extractImages, useImageGeneration, useMessages } from './useGeneration';
import { ImageTileComponent } from './InspirationPanel';
import SettingsIcon from '@mui/icons-material/Settings';


const CreatePanelComponent = ({ isControlPanelVisible, getControlValues, setIsControlPanelVisible }) => {
  const messageText= useRef('');
  const { messages, addMessage, updateMessageImages } = useMessages();

  const handleSendMessage = () => {
    const controlValues = getControlValues();
    addMessage(messageText.current, controlValues);
    messageText.current = ''
  };

  const setPrompt = (value) => {
    messageText.current = value
  }

  return (
    <Box sx={{height: "90vh"}}>
      <PromptField setPrompt={setPrompt} handleSendMessage={handleSendMessage} isControlPanelVisible={isControlPanelVisible} setIsControlPanelVisible={setIsControlPanelVisible}/>
      <SimpleTabs messages={messages} updateMessageImages={updateMessageImages} sx={{
        marginLeft: "10px", marginRight: "10px", marginBottom: "10px",
      }} />
    </Box>
  )
}

const PromptField = ({ setPrompt, handleSendMessage, isControlPanelVisible, setIsControlPanelVisible }) => {
  const [messageText, setMessageText] = useState('')
  const [uploadedImages, setUploadedImages] = useState([])
  const onChange = ( (e) => {
    setMessageText(e.target.value) 
    setPrompt(e.target.value)
  })
  const onClick = ( () => {
    handleSendMessage()
    setMessageText('')
  })
  const addImage = ( ( image ) => {
    const tmp = messageText + " ![image](" + image + ")"
    setUploadedImages([...uploadedImages, image]);
    setMessageText(tmp);
    setPrompt(tmp);
  })

  return (<>
    <TextField
      placeholder="Describe the Image ..."
      multiline
      minRows={1}
      rowsMax={4}
      value={messageText}
      onChange={onChange}
      fullWidth
      variant="outlined"
      InputProps={{
        style: { paddingBottom: '3.75rem' }, // 留出空间放置按钮
        endAdornment: (
          <InputAdornment position="end">
            <Box sx={{
              display: 'flex', justifyContent: 'space-between', width: "96%", height: "2.25rem",
              position: 'absolute', bottom: 15, left: "2%",
            }}>
              <ImageUploadButton addImage={addImage} />
              <Box>
                <Button onClick={() => { setIsControlPanelVisible(!isControlPanelVisible) }} endIcon={<SettingsIcon />}
                  sx={{ background: "transparent", marginRight:"1rem"}}>
                  settings
                </Button>
                <Button onClick={onClick} variant="contained" endIcon={<SendIcon />}
                  sx={{ background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)" }}>
                  Generate
                </Button>
              </Box>
            </Box>
          </InputAdornment>
        )
      }}
      sx={{
        backgroundColor: theme.palette.background.paper,
        width: "98%", marginLeft: "1%"
      }}
    /></>
  )
}

const ImageUploadButton = ({ addImage }) => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedAvatars, setSelectedAvatars] = useState([]);
  const fileInputRef = useRef(null);

  const handleUpload = (event) => {
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    axios.post('/api/v1/upload/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((response) => {
      setSelectedAvatars([ ...selectedAvatars, response.data.snapshot_filename])
      addImage(response.data.filename)
      console.log('File uploaded successfully:', response.data);
    });
  };
  useEffect(() => {
    console.log('selectedAvatars:', selectedAvatars.length, selectedAvatars);
  }, [selectedAvatars]);

  const handleIconButtonClick = () => {
    console.log("handleIconButtonClick", fileInputRef)
    fileInputRef.current.click();
  };
  const handleRemoveImage = (index) => {
    // Create a new array without the image at the specified index
    const newSelectedAvatars = selectedAvatars.filter((_, idx) => idx !== index);
    setSelectedAvatars(newSelectedAvatars);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row"}}>
      <Box sx={{ marginRight: "10px", display: 'flex', alignItems: 'center' }}>
        <Typography sx={{fontFamily: 'Tahoma, Verdana, sans-serif'}}>
          Add Character:
        </Typography>
      </Box>
      {selectedAvatars.length > 0 && (
        selectedAvatars.map((item, index) => (
          <Box>
          <Avatar 
            key={index}
            src={item}
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
              borderRadius: "5px",
              marginRight: "10px",
            }} 
          />
          <button
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              borderRadius: '50%',
              border: 'none',
              // Other styles for the close button as needed
            }}
            onClick={() => handleRemoveImage(index)}
          >
            &#10005; {/* This is a simple text X. Replace with an icon if needed */}
          </button>
          </Box>
        ))
      )}
      <IconButton
        variant="outlined"
        sx={{
          borderRadius: '50%', // Makes the button circular
          borderWidth: '2px', // Adjust the border width as needed
          borderStyle: 'dashed', // Makes the border dashed
          width: "2.1rem",
          height: "2.1rem",
          marginTop: "0.2rem"
        }}
        onClick={handleIconButtonClick}
      >
        <AddIcon  sx={{color: "#ff6633"}}/>
        <input type="file" onChange={handleUpload} ref={fileInputRef}/>
      </IconButton>
    </Box>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function SimpleTabs({ messages, updateMessageImages }) {
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{marginTop: "0.5rem"}}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', margin: 0, padding: 0}}>
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Creations" {...a11yProps(0)} />
          <Tab label="Inspiration" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Chatbox  messages={messages} updateMessageImages={updateMessageImages} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ImageTileComponent/>
      </TabPanel>
    </Box>
  );
}


const Chatbox = React.memo(({ messages, updateMessageImages }) => {
  console.log(messages.length, "Chatbox rendering ...")
  return (
    <Box sx={{ overflowY: 'auto', marginTop: "0px", padding: "10px"}}>
      {messages.map((message, index) => (
        <Box key={index} sx={{ "margin": "10px" }}> 
          <MessageResponse message={message} updateMessageImages={updateMessageImages} key={message.request_id} />
        </Box>
      ))}
    </Box>
  );
});

const MessageResponse = React.memo(({ message, updateMessageImages }) => {
  const [isLoading, setIsLoading] = useState(false);
  console.log(message.request_id, message.images, isLoading, "Message rendering ...")
  useEffect(() => {
    console.log(message.request_id, "useEffect ...", message.images[0], message.images[0] == "" && !isLoading)
    if (message.images[0] == "" && !isLoading) {
      setIsLoading(true);
      console.log(message.request_id, "loading ...")
      const controlValues = message.controlValues;
      const { updatedText, imagePaths } = extractImages(message.text)
      console.log("imagePaths", imagePaths, updatedText, message.text)

      const request_params = {
        "request_id": message.request_id,
        "prompt": updatedText,
        "cfg_guidance": controlValues.guidanceScale,
        "height": parseInt(controlValues.imageResolution.split("x")[0]),
        "width": parseInt(controlValues.imageResolution.split("x")[1]),
        "image_count": controlValues.imageGenerationCount,
        "scheduler_beta_start": controlValues.betaStart,
        "scheduler_beta_end": controlValues.betaEnd
      }
      if (imagePaths.length > 0) {
        const centerX = request_params.width * controlValues.faceX;
        const centerY = request_params.height * controlValues.faceY;
        const faceSize = (request_params.width + request_params.height) * controlValues.faceScale / 2;
        const x1 = parseInt(Math.max(0, centerX - faceSize));
        const y1 = parseInt(Math.max(0, centerY - faceSize));
        const x2 = parseInt(Math.min(request_params.width, centerX + faceSize));
        const y2 = parseInt(Math.min(request_params.height, centerY + faceSize));

        request_params.ref_control = {
          ref_images: {
              face: [ imagePaths ]
          },
          bbox: [
              [x1, y1, x2, y2], 
          ],
          expected_person_count: 1,

        }
      }
      console.log("fetching image ...", message, request_params)
        axios.post('/api/v1/gen/', request_params)
          .then((response) => {
            if (response.status == 200) {
              const images = response.data.image_ad_urls.map(
                (url) => '/api/v1/images/gen/' + url.split("/").pop());
              updateMessageImages(message, images)
              console.log('Image generated successfully:', images);
              setIsLoading(false);
            } else {
              console.error('Image generation failed:', response);
            }
          })
          .catch(error => {
            console.error('Request failed', error);
            // Further error handling (e.g. setting state to show a user-friendly message)
          });

    }
  }, [])

  return (
    <Box sx={{ marginBottom: "10px" }}>
      <Typography variant="body1" gutterBottom>
        {message.text}
      </Typography>
      <Grid container spacing={1} alignItems="center" sx={{ width: "95%" }}>
        {message.images.map((img, i) => (
          img ? (<Grid item xs={12} md={6} key={i}>
            <img src={img} alt="Generated Image" style={{ 
              width: "100%", 
              borderRadius: "5px"
            }} />
          </Grid>) : (<Grid item xs={12} md={6} key={i} sx={{ width: "95%" }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <ImageIcon sx={{ fontSize: 180, opacity: 0.5 }} />
              <Box sx={{
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <CircularProgress sx={{ color: 'white' }} />
              </Box>
            </Box>
          </Grid>))
        )}
      </Grid>
    </Box>
  );
});

export default CreatePanelComponent;
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  // 您可以在这里调整主题，如颜色和字体
  palette: {
    mode: "light",
    background: {
    },
  },
  // typography: {
  //   // 设置 Typography 组件的颜色
  //   body1: {
  //     color: 'white', // 设置 h5 标题的颜色为白色
  //   },
  //   h5: {
  //     color: 'white', // 设置 h6 标题的颜色为白色
  //   },
  // },
  components: {
    // 覆写MuiListItem样式
    MuiListItem: {
      root: {
        // 定义您想要的样式
        border: '1px solid #ccc', // 这里是边框
        padding: '30px' // 这是内边距
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            color: 'white', 
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '15px',
          '& .MuiInputBase-root': {
            borderRadius: '15px',
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        thumb: {
          height: 16, // 滑块控制圈的高度
          width: 16, // 滑块控制圈的宽度
          backgroundColor: '#fff', // 滑块控制圈的颜色
          borderRadius: '50%', // 滑块控制圈的圆角
          boxShadow: '0 4px 3px rgba(0, 0, 0, 0.25)', // 滑块控制圈的阴影效果
        },
        track: {
          height: 16, // 左边实体颜色部分的高度
          borderTopLeftRadius: "15px", // 滑块控制圈的圆角
          borderBottomLeftRadius: "15px", // 滑块控制圈的圆角
          // background: `linear-gradient(to right, #666666, #6495ED )`, // 左边实体蓝紫渐变
          background: `linear-gradient(45deg, #FF8E53 90%, #FE6B8B 30% )`
        },
        rail: {
          height: 16, // 右边虚线颜色部分的高度
          borderTopRightRadius: "15px", // 滑块控制圈的圆角
          borderBottomRightRadius: "15px", // 滑块控制圈的圆角
          backgroundColor: 'transparent', // 右边虚线颜色部分的背景色
          background: `#AAAAAA`, // 左边实体蓝紫渐变
        },
      },
    },
  },
});

export default theme;
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useCallback, useState, useEffect } from 'react';
import axios from 'axios';


export const useMessages = () => {
  const [messages, setMessages] = useState([]);

  const addMessage = useCallback((text, controlValues) => {
    const newMessage = {
      request_id: uuidv4(),
      text: text,
      timestamp: new Date(),
      imageNumber: controlValues.imageGenerationCount,
      images: Array.from({ length: controlValues.imageGenerationCount, }, () => ""),
      controlValues: controlValues
    };
    setMessages((prevMessages) => [newMessage, ...prevMessages]);
  }, []);

  const updateMessageImages = useCallback((message, newImages) => {
    message.images = newImages;
  }, []);

  return { messages, addMessage, updateMessageImages };
};

export const extractImages = (text) => {
  const host = window.location.origin; // Automatically get the host
  const imagePattern = /!\[image]\((\/api\/v1\/images\/upload\/[^)]+)\)/g;
  let match;
  let updatedText = text;
  const imagePaths = [];

  while ((match = imagePattern.exec(text)) !== null) {
    const imagePathWithHost = host + match[1];
    imagePaths.push(imagePathWithHost);
    updatedText = updatedText.replace(match[0], '');
  }
  return { updatedText, imagePaths };
};

export const useTabSelections = () => {
  const [currentTab, setCurrentTab] = useState(1);

  const handleTabChange = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  return { currentTab, handleTabChange };
}
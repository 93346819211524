

import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; 

// Replace 'yourImages' with your actual images array
const example_images = [
  "gen/354753a7-f939-4c1c-8742-7854af5041ad_1_ad.png",
  "gen/81da4a6b-2e17-437b-bfa1-ac5513392966_0_ad.png",
  "gen/819fac1f-ccf1-46ba-9c7f-d9ed138a337d_2_ad.png",
  "gen/414c6c77-fedc-42bf-9e91-826248d45fe6_3_ad.png",
  "gen/d4db062d-ca3b-438e-8e51-6f95e6336003_1_ad.png",
  "gen/eb991562-332c-488a-a273-e24f08778b97_2_ad.png",
  "gen/0d94dab8-252a-4a84-b412-8ffb5c7ad5bd_0_ad.png",
  "gen/48c2f6d7-43f0-4819-98b2-34ce3e33081d_0_ad.png",
  "gen/7d060a67-3aa2-479d-83f8-0ffcb2d6fe13_2_ad.png",
  "gen/939d035a-8bfb-4a21-a7e8-ed770eaeefaf_2_ad.png",
  "gen/d5683b5a-2e27-451a-86e1-4030392199d7_3_ad.png",
  "gen/81da4a6b-2e17-437b-bfa1-ac5513392966_1_ad.png",
  "gen/bdcf5dc7-50ad-4dcd-b71f-f0f5859beba2_2_ad.png",
  "gen/c2c2d4ca-a02d-42ae-8071-3b3f114d45e0_1_ad.png",
].map((item) => ({
      img: '/api/v1/images/' + item,
      title: 'image',
    }));

export  function ImageTileComponent() {
  return (
    <Box sx={{ width: '100%', height: 'auto', overflowY: 'auto'}}>
      <ImageList variant="masonry" cols={3} gap={8}>
        {example_images.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={`${item.img}?w=248&fit=crop&auto=format`}
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              style={{borderRadius: "15px"}}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
}

import React from 'react';
import {
  Container,
  Typography,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  Grid,
  Slider,
  Box,
  Drawer
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';


function ExclusiveSelection({
    optionalValues,
    cur_value,
    numButtonPerRow,
    onCurrentValueChange,
}) {
    const [selected, setSelected] = React.useState(cur_value);

    const handleSelection = (event, newSelection) => {
      if (newSelection !== null) {
        setSelected(newSelection);
      }
      onCurrentValueChange(event)
    };
    const buttonLabels = optionalValues;
    const gridSize = Math.floor(12 / numButtonPerRow);

    return (
      <ToggleButtonGroup
        value={selected}
        exclusive
        onChange={handleSelection}
        aria-label="exclusive selection"
        sx={{paddingLeft: "10px"}}
        fullWidth
      >
        <Grid container spacing={1} fullWidth>
        {buttonLabels.map(label => (
          <Grid item xs={12} sm={gridSize} key={label}>
            <ToggleButton value={label} aria-label={label.toLowerCase()} fullWidth sx={{
                fontSize: "0.7rem", width:"100%", textTransform: 'none' }}>
              {label}
            </ToggleButton>
          </Grid>
        ))}
      </Grid>
      </ToggleButtonGroup>
    );
  }


export default function ControlPanel({
    controlValues, 
    onControlChange
}) {
  const theme = useTheme()
  console.log("rendering ControlPanel: ", controlValues)

  const handleCountChange = (event) => {
    onControlChange('imageGenerationCount', parseInt(event.target.value));
  };

  const handleResolutionChange = (event) => {
    onControlChange('imageResolution', event.target.value);
  };

  const getSliderChangeHandler = (name) => {
    return ((event, newValue) => {
      onControlChange(name, parseFloat(newValue));
    })
  }

  return (
      <Box sx={{margin: "1.5rem"}}>

        {/* Image Generation Count */}
        <Typography variant="body1" sx={{padding: "10px"}}>
            Image Numbers
        </Typography>
        <ExclusiveSelection 
            optionalValues={["1", "2", "3", "4"]} 
            cur_value={controlValues.imageGenerationCount.toString()}
            numButtonPerRow={4}
            onCurrentValueChange={handleCountChange} 
        />
        <Divider sx={{padding: "10px"}}/>

        {/* Image Resolution */}
        <Typography variant='body1' sx={{padding: "10px"}}>
            Image Resolution
        </Typography>
        <ExclusiveSelection 
            optionalValues={['768x768', '768x1024', '1024x768', '1024x1024', '896x1344', '1344x896']}
            cur_value={controlValues.imageResolution}
            numButtonPerRow={3}
            onCurrentValueChange={handleResolutionChange} 
        />
        <Divider sx={{padding: "10px"}}/>
        
        {/* Guidance Scale */}
        <Typography variant='body1' sx={{padding: "10px"}}>
            Guidance Scale
        </Typography>
        <Box sx={{ marginRight: "10px", marginLeft: "10px"}}>
            <Slider
            defaultValue={controlValues.guidanceScale}
            onChange={getSliderChangeHandler("guidanceScale")}
            aria-labelledby="guidance-scale-slider"
            valueLabelDisplay="auto"
            step={1}
            marks
            min={0}
            max={8}
            />
        </Box>
        <Divider sx={{padding: "10px"}}/>
        
        <Typography variant='body1' sx={{padding: "10px"}}>
            Advanced Settings
        </Typography>
        <Box sx={{ marginRight: "10px", marginLeft: "10px"}}>
          <Typography variant='body2' sx={{padding: "5px"}}>
              Scheduler Beta (Start/End)
          </Typography>
            <Slider
            defaultValue={controlValues.betaStart}
            onChange={getSliderChangeHandler("beta_start")}
            aria-labelledby="beta_start"
            valueLabelDisplay="auto"
            step={0.0001}
            marks
            min={0.0005}
            max={0.0015}
            />
            <Slider
            defaultValue={controlValues.betaEnd}
            onChange={getSliderChangeHandler("beta_end")}
            aria-labelledby="beta_end"
            valueLabelDisplay="auto"
            step={0.001}
            marks
            min={0.008}
            max={0.015}
            />
            <Divider sx={{padding: "10px"}}/>
        </Box>

        <Box sx={{ marginRight: "10px", marginLeft: "10px"}}>
          <Typography variant='body2' sx={{padding: "5px"}}>
              Reference Face Geometry (on Upload)
          </Typography>
          <Typography variant='body2' sx={{padding: "5px"}}>
              Face Position X
          </Typography>
            <Slider
            defaultValue={controlValues.faceX}
            onChange={getSliderChangeHandler("faceX")}
            aria-labelledby="faceX"
            valueLabelDisplay="auto"
            step={0.05}
            marks
            min={0}
            max={1}
            />
          <Typography variant='body2' sx={{padding: "5px"}}>
              Face Position Y
          </Typography>
            <Slider
            defaultValue={controlValues.faceY}
            onChange={getSliderChangeHandler("faceY")}
            aria-labelledby="faceY"
            valueLabelDisplay="auto"
            step={0.05}
            marks
            min={0}
            max={1}
            />
          <Typography variant='body2' sx={{padding: "5px"}}>
              Face Scale
          </Typography>
            <Slider
            defaultValue={controlValues.faceScale}
            onChange={getSliderChangeHandler("faceScale")}
            aria-labelledby="faceScale"
            valueLabelDisplay="auto"
            step={0.05}
            marks
            min={0}
            max={0.5}
            />
            <Divider sx={{padding: "10px"}}/>
        </Box>
      </Box>
  );
}

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import MainContent from './components/CreatePage';
import Box from '@mui/material/Box';
import Sidebar from './components/SideBar';
import { UserProvider } from './utils/user';

function App() {
  return (
    <UserProvider>
    <ThemeProvider theme={theme}>
      <Router>
        <Box display="flex" flexDirection sx={{
          backgroundImage: `radial-gradient(at 40% 10%, rgba(191, 57, 137, 0.1) 0px, rgba(0, 0, 0, 0) 75%), radial-gradient(at 60% 10%, rgba(9, 107, 222, 0.1) 0px, rgba(0, 0, 0, 0) 75%)`
          }}>
          <Sidebar/>
          <Box sx={{ width: '90%'}}>
            <Routes>
              <Route exact path="/" element={<MainContent />} />
            </Routes>
          </Box>
        </Box>
      </Router>
    </ThemeProvider>
    </UserProvider>
  );
}

export default App;
import React from 'react';

import { Box, ListItemIcon, ListItemButton, List, ListItemText, Stack, Divider, styled, Typography, Avatar } from '@mui/material';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import CommunityIcon from '@mui/icons-material/People';
import ProfileIcon from '@mui/icons-material/AccountBox';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MessageIcon from '@mui/icons-material/Message';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import CollectionsIcon from '@mui/icons-material/Collections';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

import { Link, useLocation } from 'react-router-dom';
import logo from '../images/ps.png'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Image } from '@mui/icons-material';

export default function Sidebar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();

  const sidebarStyle = {
    width: isMobile ? '50vw' : '16rem', // Adjust the width based on the device type
    minWidth: '10rem', // Prevent the sidebar from becoming too narrow
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    padding: "0px",
    borderRight: "1px solid #E0E0E0"
  };

  const CustomListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    marginRight: "-20px",
    color: 'inherit', // default color
    
  }))

  const primaryTypographyProps = {
    style: {
      fontWeight: '500', // For bold text
      fontFamily: 'Tahoma, Verdana, sans-serif',
      fontSize: "calc(0.97rem)", // Sets the font size
      letterSpacing: "calc(0.02rem)"
    },
  }
  const CustomListItemButton = styled(ListItemButton)(({ theme }) => ({
    margin: "0px",
    paddingLeft: "10px",
    paddingRight: "0px",
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "5px",
    borderRadius: "10px",
    '&.Mui-selected': {
      '& .MuiListItemIcon-root': {
        color: '#EE6B2B',
      },
    },
  }))

  return (
    <Box className="sidebar" sx={sidebarStyle}>
      <Box sx={{ flexGrow: 1 }}>
        <List component="nav">
          <CustomListItemButton className="hometab" component={Link} to="/home" selected={location.pathname === "/home"} 
            sx={{ marginBottom: "10px", marginTop: "10px"}}>
            <Avatar sx={{height: "50px", width: "45px", marginLeft:"-5px" }} src={logo}></Avatar>

            <Typography variant="h6" component="div" sx={{
              // Apply common styles to the entire Typography component here
              '& .picksoul': {
                fontSize: '1.3rem',
                fontWeight: 'bold',
                fontFamily: 'Tahoma, Verdana, sans-serif',
              },
              '& .art': {
                fontSize: '1.1rem',
                fontWeight: 'bold',
                fontFamily: "Tahoma, Verdana, sans-serif",
                backgroundImage: `linear-gradient(100deg, #FE2B8B, #FF8E33)`,
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',  // This is for Safari.
                color: 'transparent',
                WebkitTextFillColor: 'transparent'
              },
              flexGrow: 1, marginLeft: "10px" 
            }}>
              <span className='picksoul'>PickSoul.</span>
              <span className='art'>Art</span>
            </Typography>
          </CustomListItemButton>

          <CustomListItemButton component={Link} to="/" selected={location.pathname === "/"}>
            <CustomListItemIcon>
              <ColorLensIcon sx={{'.Mui-focusVisible &': { 
                color: 'primary.main', // the color you want for the icon on focus
              }}}/>
            </CustomListItemIcon>
            <ListItemText primary="Image Creation" primaryTypographyProps={primaryTypographyProps}/>
          </CustomListItemButton>

          <CustomListItemButton component={Link} to="/create_char" selected={location.pathname === "/create_char"}>
            <CustomListItemIcon>
              <FaceRetouchingNaturalIcon sx={{'.Mui-focusVisible &': { 
                color: 'primary.main', 
              }}}/>
            </CustomListItemIcon>
            <ListItemText primary="Character Build" primaryTypographyProps={primaryTypographyProps}/>
          </CustomListItemButton>

          <CustomListItemButton component={Link} to="/edit" selected={location.pathname === "/edit"}>
            <CustomListItemIcon>
              <AutoFixHighIcon sx={{'.Mui-focusVisible &': { 
                color: 'primary.main', 
              }}}/>
            </CustomListItemIcon>
            <ListItemText primary="Image Edit" primaryTypographyProps={primaryTypographyProps}/>
          </CustomListItemButton>

          <CustomListItemButton component={Link} to="/community" selected={location.pathname === "/community"}>
            <CustomListItemIcon>
              <CollectionsIcon />
            </CustomListItemIcon>
            <ListItemText primary="Galleries" primaryTypographyProps={primaryTypographyProps}/>
          </CustomListItemButton>
          <CustomListItemButton component={Link} to="/profile" selected={location.pathname === "/profile"}>
            <CustomListItemIcon>
              <ProfileIcon />
            </CustomListItemIcon>
            <ListItemText primary="Profile" primaryTypographyProps={primaryTypographyProps} />
          </CustomListItemButton>
        </List>
        <Divider></Divider>
        <List component="nav">
      
          <CustomListItemButton component={Link} to="/feedback" selected={location.pathname === "/feedback"}>
            <CustomListItemIcon>
              <MessageIcon />
            </CustomListItemIcon>
            <ListItemText primary="Feedback"  primaryTypographyProps={primaryTypographyProps}/>
          </CustomListItemButton>
          <CustomListItemButton component={Link} to="/join" selected={location.pathname === "/join"}>
            <CustomListItemIcon>
              <GroupAddIcon />
            </CustomListItemIcon>
            <ListItemText primary="Join Group" primaryTypographyProps={primaryTypographyProps} />
          </CustomListItemButton>
        </List>

      </Box>
      <Divider></Divider>

      <Box sx={{marginBottom: "30px"}}>
        <List component="nav">
          <CustomListItemButton component={Link} to="/signin" selected={location.pathname === "/signin"}>
            <CustomListItemIcon>
              {/* Assuming you have a SignInIcon */}
              <AccountCircleIcon />
            </CustomListItemIcon>
            <ListItemText primary="Sign In" primaryTypographyProps={primaryTypographyProps} />
          </CustomListItemButton>
          <CustomListItemButton component={Link} to="/settings" selected={location.pathname === "/settings"}>
            <CustomListItemIcon>
              {/* Assuming you have a SettingsIcon */}
              <SettingsIcon />
            </CustomListItemIcon>
            <ListItemText primary="Settings" primaryTypographyProps={primaryTypographyProps} />
          </CustomListItemButton>
        </List>
      </Box>
    </Box>
  );
}
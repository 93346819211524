import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Box, Button, useTheme, Drawer, IconButton, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ImageIcon from '@mui/icons-material/Image';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import styled from '@mui/system/styled';
import axios from 'axios';
import ControlPanel from './ControlPanel';
import CreatePanel from './CreatePanel';
import theme from '../theme';

const drawerWidth = 300;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
    /**
     * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
     * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
     * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
     * proper interaction with the underlying content.
     */
    position: 'relative',
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: "2rem",
  paddingLeft: "1rem",
  justifyContent: 'flex-start',
}));



const MainContent = () => {
  const [isControlPanelVisible, setIsControlPanelVisible] = useState(false);
  const [tabFocusedIndex, setTabFocusedIndex] = useState(1);
  const [controlValues, setControlValues] = useState({
    imageGenerationCount: 2,
    imageResolution: '768x1024',
    guidanceScale: 5,
    betaStart: 6e-4,
    betaEnd: 9e-3,
    faceX: 0.4,
    faceY: 0.2,
    faceScale: 0.15
  })
  const getControlValues = () => {
    const values = { ... controlValues };
    console.log("getControlValues", values);
    return values;
  };
  const theme = useTheme();

  const handleControlChange = (name, value) => {
    console.log("controlValues change", name, "from", controlValues[name], "to", value, controlValues);
    controlValues[name] = value;
  };
  console.log("MainContent rendering ...")

  return (
    <Box sx={{ 
        display: 'flex', height: '100%', width: '100%', 
      }}>

      <Main open={isControlPanelVisible}>
        <CreatePanel isControlPanelVisible={isControlPanelVisible} getControlValues={getControlValues} setIsControlPanelVisible={setIsControlPanelVisible} />
      </Main>


      <Drawer 
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={isControlPanelVisible}
      > {/* Add padding to the top of the box to avoid overlap with the floating button */}
        <DrawerHeader>
          <IconButton onClick={() => { setIsControlPanelVisible(false) }} >
            {theme.direction === 'rtl' ? <ChevronLeftIcon style={{ fontSize: '3rem' }} /> : <ChevronRightIcon />}
          </IconButton>
          <Typography variant="h6">Settings</Typography>
        </DrawerHeader>
        <ControlPanel controlValues={controlValues} onControlChange={handleControlChange} />
      </Drawer>

    </Box>
  );
};

export default MainContent;
